import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import "./src/styles/reset.css"
import "./src/styles/variables.css"
import "./src/styles/global.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import "@fontsource/montserrat" // Defaults to weight 400 with normal variant.

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)
